import React, { useEffect, useState, useContext } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';

import { Field, FieldArray, Formik } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { error, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import 'moment/locale/pt-br';
import {
    AddButton,
    ContainerPhone,
} from '../../../components/form-components/repeater/styles';
import { LabelMessage } from '../../../components/form-components/styles';
import { parse } from '../../../components/form-components/tags/utils/parse';
import PageContainer from '../../../components/page-container';
import Resizer from '../../../components/resizer';
import { colors } from '../../../configs/theme';
import Request from '../../../utils/Request';
import { pathname2Query } from '../../../utils/RouterRes';
import { parser } from '../../../utils/Select';
import { screens } from '../../../utils/Theme';
import Atividades from './atividades';

moment.locale('pt-br');

const formValues = {
    nome: '',
    email: '',
    estado: {},
    cidade: {},
    telefones: [{ id: '', numero: '' }],
    bairro: {},
    rua: '',
    complemento: '',
    geolocalizacao: [],
    sexo: '',
};

function Pessoa() {
    const [pessoa, setPessoa] = useState({});
    const location = useLocation();
    const match = useRouteMatch();
    const url_params = pathname2Query(location.pathname, match.url);
    const history = useHistory();
    const initialValues =
        pessoa && Object.keys(pessoa).length > 0 ? pessoa : { ...formValues };
    const { screenSize, user } = useContext(AppContext);
    const [canEdit, setCanEdit] = useState(true);

    async function getPessoa(id) {
        const request = new Request();

        const req_pessoa = request.setRequest('pessoas', 'listar', { id });

        const result = await request.execute();

        const { dados } = result[req_pessoa];

        if (dados && dados.id) {
            const pessoa_to_edit = {};
            pessoa_to_edit.nome = dados.nome;
            pessoa_to_edit.email = dados.email;
            pessoa_to_edit.rua = dados.rua;
            pessoa_to_edit.complemento = dados.complemento;
            pessoa_to_edit.sexo = dados.sexo;
            pessoa_to_edit.id = dados.id;

            if (dados.geolocalizacao) {
                pessoa_to_edit.geolocalizacao = dados.geolocalizacao.split(
                    ', '
                );
            } else {
                pessoa_to_edit.geolocalizacao = [];
            }

            if (dados.bairro_id && !isNaN(dados.bairro_id)) {
                pessoa_to_edit.bairro = {
                    value: dados.bairro_id,
                    label: dados.bairro_descricao,
                };
            } else {
                pessoa_to_edit.bairro = {};
            }

            if (dados.estado_id && !isNaN(dados.estado_id)) {
                pessoa_to_edit.estado = {
                    value: dados.estado_id,
                    label: dados.estado_descricao,
                };
            } else {
                pessoa_to_edit.estado = {};
            }

            if (dados.cidade_id && !isNaN(dados.cidade_id)) {
                pessoa_to_edit.cidade = {
                    value: dados.cidade_id,
                    label: dados.cidade_descricao,
                };
            } else {
                pessoa_to_edit.cidade = {};
            }

            if (
                dados.telefones &&
                Array.isArray(dados.telefones) &&
                dados.telefones.length > 0
            ) {
                pessoa_to_edit.telefones = dados.telefones;
            } else {
                pessoa_to_edit.telefones = [{ id: '', numero: '' }];
            }

            if (user.id !== dados.usuario_id) {
                setCanEdit(false);
            }

            setPessoa(pessoa_to_edit);
        } else {
            history.push('/pessoas');
        }
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getPessoa(url_params.id).then();
        } else {
            setCanEdit(true);
            setPessoa({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    async function handleSubmit(values, state) {
        const request = new Request();
        const pessoa_to_save = {};

        if (pessoa.id) {
            pessoa_to_save.id = pessoa.id;
        }

        pessoa_to_save.nome = values.nome;
        pessoa_to_save.email = values.email;
        pessoa_to_save.rua = values.rua;
        pessoa_to_save.sexo = values.sexo;
        pessoa_to_save.complemento = values.complemento;

        if (values.estado.value) {
            pessoa_to_save.estado = values.estado.value;
        } else {
            pessoa_to_save.estado = null;
        }

        if (values.cidade.value) {
            pessoa_to_save.cidade = values.cidade.value;
        } else {
            pessoa_to_save.cidade = null;
        }

        if (values.bairro.value) {
            pessoa_to_save.bairro = values.bairro.value;
        } else {
            pessoa_to_save.bairro = null;
        }

        if (values.geolocalizacao.length >= 2) {
            pessoa_to_save.geolocalizacao = values.geolocalizacao.join(', ');
        } else {
            pessoa_to_save.geolocalizacao = null;
        }

        const telefones = values.telefones.filter((telefone) => {
            return (
                (telefone.id !== '' && telefone.numero !== '') ||
                (telefone.id === '' && telefone.numero !== '')
            );
        });

        const loadToast = loading('Salvando pessoa');

        try {
            const req_pessoa = request.setRequest('pessoas', 'salvar', {
                pessoa: pessoa_to_save,
                telefones,
            });

            const result = await request.execute();

            if (result[req_pessoa] === true) {
                loadToast();
                success(`${pessoa_to_save.nome} alterada com sucesso`);
                state.setSubmitting(false);
            } else if (Number(result[req_pessoa]) > 0) {
                loadToast();
                success(`${pessoa_to_save.nome} adicionada com sucesso`);
                history.push(`/pessoas/editor/id=${result[req_pessoa]}`);
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar pessoa!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar pessoa!');
            state.setSubmitting(false);
        }
    }

    async function getEstadosSelect(search) {
        const request = new Request();

        const req_estados = request.setRequest('estados', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_estados] || [];

        return parser('descricao', 'id', dados);
    }

    async function getCidadesSelect(search, estado_id) {
        if (estado_id && Number(estado_id) > 0) {
            const request = new Request();

            const req_cidades = request.setRequest('cidades', 'listar', {
                search,
                estado_id,
            });
            const result = await request.execute();

            const { dados } = result[req_cidades] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function getBairrosSelect(search, cidade_id) {
        if (cidade_id && Number(cidade_id) > 0) {
            const request = new Request();

            const req_bairros = request.setRequest('bairros', 'listar', {
                search,
                cidade_id,
            });
            const result = await request.execute();

            const { dados } = result[req_bairros] || [];

            return parser('descricao', 'id', dados);
        }
        return [];
    }

    async function handleBairroCreate(input_value, formState, input_name) {
        if (formState.values.cidade.value) {
            const request = new Request();

            const req_new_bairro = request.setRequest('bairros', 'salvar', {
                bairro: {
                    descricao: input_value,
                    cidade: formState.values.cidade.value,
                },
            });

            const result = await request.execute();

            if (result && result[req_new_bairro]) {
                formState.setFieldValue(input_name, {
                    label: input_value,
                    value: result[req_new_bairro],
                });
            }
        } else {
            error(
                'Necessário selecionar a cidade antes de adicionar um novo bairro!',
                'Erro!'
            );
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="nome"
                        placeholder="Nome"
                        label="Nome"
                        maxLength={255}
                        disabled={!canEdit}
                        type="text"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="email"
                        maxLength={255}
                        disabled={!canEdit}
                        placeholder="E-mail"
                        label="E-mail"
                        type="text"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="geolocalizacao"
                        maxLength={255}
                        disabled={!canEdit}
                        placeholder="-19.99,-45.69"
                        label="Geolocalizaçao (Latitude,Longitude)"
                        type="geolocation"
                        size={4}
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="estado"
                        isClearable
                        disabled={!canEdit}
                        type_select="async"
                        type="select"
                        label="Estado"
                        placeholder="Estado"
                        size={4}
                        mountcallback={(form) => {
                            async function getCidadeConfig() {
                                const request = new Request();

                                const req_cidade_config = request.setRequest(
                                    'estados',
                                    'get_cidade_estado_config',
                                    {}
                                );

                                const result = await request.execute();

                                if (
                                    result &&
                                    result[req_cidade_config] &&
                                    result[req_cidade_config].id
                                ) {
                                    const data = result[req_cidade_config];
                                    form.setFieldValue('estado', {
                                        label: data.estado_descricao,
                                        value: data.estado,
                                    });
                                    form.setFieldValue('cidade', {
                                        label: data.descricao,
                                        value: data.id,
                                    });
                                }
                            }

                            if (!url_params.id) {
                                getCidadeConfig();
                            }
                        }}
                        onChange={(value) => {
                            if (
                                value.value &&
                                formState.values.estado.value &&
                                value.value !== formState.values.estado.value
                            ) {
                                formState.setFieldValue('cidade', {});
                                formState.setFieldValue('bairro', {});
                            } else if (!value.value) {
                                formState.setFieldValue('cidade', {});
                                formState.setFieldValue('bairro', {});
                            }
                        }}
                        defaultOptions
                        cacheOptions
                        loadOptions={getEstadosSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="cidade"
                        disabled={!canEdit || !formState.values.estado.value}
                        type_select="async"
                        type="select"
                        label="Cidade"
                        onChange={(value) => {
                            if (
                                value.value &&
                                formState.values.cidade.value &&
                                value.value !== formState.values.cidade.value
                            ) {
                                formState.setFieldValue('bairro', {});
                            } else if (!value.value) {
                                formState.setFieldValue('bairro', {});
                            }
                        }}
                        placeholder="Cidade"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={(search) =>
                            getCidadesSelect(
                                search,
                                formState.values.estado.value
                            )
                        }
                    />
                    <Field
                        component={ControlledInput}
                        name="bairro"
                        isClearable
                        disabled={!canEdit || !formState.values.cidade.value}
                        type_select="async_creatable"
                        type="select"
                        onCreateOption={(input_value) =>
                            handleBairroCreate(input_value, formState, 'bairro')
                        }
                        label="Bairro"
                        placeholder="Bairro"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={(search) =>
                            getBairrosSelect(
                                search,
                                formState.values.cidade.value
                            )
                        }
                    />
                </Row>
                <Row height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="rua"
                        disabled={!canEdit}
                        placeholder="Rua"
                        maxLength={255}
                        label="Rua"
                        type="text"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="complemento"
                        disabled={!canEdit}
                        placeholder="Complemento"
                        maxLength={255}
                        label="Complemento"
                        type="text"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        options={[
                            { label: 'Masculino', value: 'M' },
                            { label: 'Feminino', value: 'F' },
                        ]}
                        size={4}
                        type="input-radio"
                        disabled={!canEdit}
                        name="sexo"
                        label="Sexo"
                        ignoremobile
                    />
                </Row>
                <Row height="auto" spaceBetween wrap="wrap">
                    <FieldArray
                        name="telefones"
                        render={(arrayHelpers) => (
                            <>
                                <Row
                                    padding={
                                        screenSize === screens.smartphone
                                            ? '0'
                                            : '0 0 0 15px'
                                    }
                                >
                                    <LabelMessage>Telefone(s)</LabelMessage>
                                </Row>
                                {formState.values.telefones.map(
                                    (telefone, index) => (
                                        <ContainerPhone key={index}>
                                            <Field
                                                component={ControlledInput}
                                                name={`telefones.${index}`}
                                                placeholder="(99) 99999-9999"
                                                type="mask"
                                                margin="0 0 10px 0"
                                                disabled={!canEdit}
                                                ignoreTypeChange
                                                onChange={(event) => {
                                                    const telefones = [
                                                        ...formState.values
                                                            .telefones,
                                                    ];
                                                    telefones[index] = {
                                                        ...telefones[index],
                                                        numero:
                                                            event.target.value,
                                                    };
                                                    formState.setFieldValue(
                                                        'telefones',
                                                        telefones
                                                    );
                                                }}
                                                mask={(val) => {
                                                    if (val.length < 15) {
                                                        return [
                                                            '(',
                                                            /[1-9]/,
                                                            /\d/,
                                                            ')',
                                                            ' ',
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            '-',
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                            /\d/,
                                                        ];
                                                    }
                                                    return [
                                                        '(',
                                                        /[1-9]/,
                                                        /\d/,
                                                        ')',
                                                        ' ',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        '-',
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                        /\d/,
                                                    ];
                                                }}
                                                size={4}
                                                indexError="numero"
                                                maskedValue={telefone.numero}
                                                indexField={index}
                                                show_filtros={index !== 0}
                                                iconFiltros="icon-trash"
                                                iconFiltrosColor={
                                                    colors.grey_chumbo_icons
                                                }
                                                onClickFiltros={() =>
                                                    arrayHelpers.remove(index)
                                                }
                                            />
                                        </ContainerPhone>
                                    )
                                )}
                                {canEdit && (
                                    <AddButton
                                        size={4}
                                        onClick={() =>
                                            arrayHelpers.push({
                                                id: '',
                                                numero: '',
                                            })
                                        }
                                    >
                                        Adicionar campo
                                    </AddButton>
                                )}
                            </>
                        )}
                    />
                </Row>
                <Row
                    ignoreMobile
                    contentEnd
                    margin="10px 0 0 0"
                    padding={screenSize === screens.smartphone ? '0' : '0 15px'}
                >
                    {url_params.id > 0 && (
                        <>
                            <Button
                                kind="save"
                                icon="icon-atendimento"
                                margin="0 10px 0 0"
                            >
                                <Link
                                    to={`/atendimentos/editor/pessoa=${url_params.id}`}
                                >
                                    Novo Atendimento
                                </Link>
                            </Button>
                            <Button
                                type="reset"
                                kind="cancel"
                                margin="0 10px 0 0"
                                onClick={() => {
                                    formState.resetForm();
                                    history.push('/pessoas/editor');
                                }}
                            >
                                Cancelar
                            </Button>
                        </>
                    )}
                    {canEdit && (
                        <Button
                            type="submit"
                            kind="save"
                            disabled={formState.isSubmitting}
                        >
                            Salvar
                        </Button>
                    )}
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Cadastro de Pessoa">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        nome: yup.string().max(255, '255 caracteres máximos'),
                        rua: yup.string().max(255, '255 caracteres máximos'),
                        cidade: yup.object(),
                        bairro: yup.object(),
                        email: yup
                            .string()
                            .uniqueEmailPessoa({
                                message: 'Esse e-mail já está cadastrado!',
                                user_atual: pessoa.id,
                            })
                            .max(255, '255 caracteres máximos'),
                        sexo: yup.string(),
                        telefones: yup.array().of(
                            yup.object().shape({
                                numero: yup
                                    .string()
                                    .isPhone('Telefone inválido!'),
                            })
                        ),
                        complemento: yup.string('Complemento Inválido!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    if (!url_params.id) {
        return (
            <PageContainer padding scroll>
                {renderForm()}
            </PageContainer>
        );
    }

    return (
        <Resizer left={50} right={50} minLeft={500} minRight={500}>
            <Atividades url_params={url_params} />
            {renderForm()}
        </Resizer>
    );
}

export default Pessoa;
