import React, { useRef, useState, useEffect, useContext } from 'react';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';

import { Row } from '../../../app/global-styles';
import { error, success } from '../../../components/alerts';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';
import { colors } from '../../../configs/theme';
import Request, { getLimit } from '../../../utils/Request';
import 'moment/locale/pt-br';
import { screens } from '../../../utils/Theme';
import FiltrosPessoas from './filtros';
import { AppContext } from '../../../app/app-container';

moment.locale('pt-br');

function Pessoas() {
    const history = useHistory();
    const tableRef = useRef();
    const [searchParams, setSearchParams] = useState({});
    const [filtrosOpen, setFiltrosOpen] = useState(false);
    const { screenSize, user } = useContext(AppContext);

    const initialFilters = {
        search: '',
        estados: [],
        cidades: [],
        bairros: [],
        sexo: {},
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    function submitFiltros({ search, estados, cidades, bairros, sexo }) {
        const search_params = {};

        search_params.search = search;
        search_params.estados = estados.map((estado) => estado.value);
        search_params.cidades = cidades.map((cidade) => cidade.value);
        search_params.bairros = bairros.map((bairro) => bairro.value);

        if (sexo && sexo.value) {
            search_params.sexo = sexo.value;
        } else {
            search_params.sexo = null;
        }

        setSearchParams(() => {
            setFiltrosOpen(false);
            return search_params;
        });
    }

    async function getPessoas({ page, limit: table_limit }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_pessoas = request.setRequest('pessoas', 'listar', {
            limit,
            ...searchParams,
        });
        const result = await request.execute();

        if (result && result[req_pessoas]) {
            return result[req_pessoas];
        }
        return {};
    }

    async function desativarPessoa({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest(
                    'pessoas',
                    'desativar',
                    { id }
                );
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Pessoa excluída com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir pessoa!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir pessoa?',
                subtitle: `Ao confirmar a pessoa ${original.nome} será excluída!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Pessoa inválida!');
        }
    }

    function makeFormFiltros(formikState) {
        return (
            <Row as="form" wrap="wrap" onSubmit={formikState.handleSubmit}>
                <FiltrosPessoas
                    formState={formikState}
                    filtrosOpen={filtrosOpen}
                    setFiltrosOpen={setFiltrosOpen}
                />
                <Field
                    name="search"
                    type="table-search"
                    placeholder="Filtrar"
                    size={1}
                    minWidth={210}
                    onChange={(e) => {
                        const { value, name } = e.target;
                        setSearchParams({ ...searchParams, [name]: value });
                    }}
                    onClickFiltros={() => {
                        setFiltrosOpen(true);
                    }}
                    clearFunction={() => {
                        setSearchParams({});
                        formikState.resetForm({ ...initialFilters });
                    }}
                    searchParams={searchParams}
                    alias={{
                        vinculos: 'vínculos',
                        tags: 'tags',
                        profissoes: 'profissões',
                        estados: 'estados',
                        cidades: 'cidades',
                        bairros: 'bairros',
                        categorias: 'categorias',
                        sexo: 'sexo',
                    }}
                    component={ControlledInput}
                />
            </Row>
        );
    }

    return (
        <PageContainer padding scroll>
            <Row margin="0 0 21px 0">
                <Formik
                    onSubmit={submitFiltros}
                    initialValues={{ ...initialFilters }}
                >
                    {makeFormFiltros}
                </Formik>
            </Row>
            <Table
                headers={
                    screenSize === screens.smartphone
                        ? [
                              {
                                  name: 'Nome',
                                  accessor: 'nome',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value || ''
                                              }
                                          />
                                      );
                                  },
                              },
                          ]
                        : [
                              {
                                  name: 'Nome',
                                  accessor: 'nome',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value || ''
                                              }
                                          />
                                      );
                                  },
                              },
                              {
                                  name: 'E-mail',
                                  accessor: 'email',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value || ''
                                              }
                                          />
                                      );
                                  },
                              },
                              {
                                  name: 'Endereço',
                                  accessor: 'endereco',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value || ''
                                              }
                                          />
                                      );
                                  },
                              },
                          ]
                }
                options={(table_props) => {
                    if (
                        table_props.original &&
                        table_props.original.usuario_id &&
                        table_props.original.usuario_id === user.id
                    ) {
                        return (
                            <Icon
                                hidden
                                size="16px"
                                hover={colors.red_error_message}
                                color={colors.black_table}
                                className="icon-trash"
                                onClick={() => desativarPessoa(table_props)}
                            />
                        );
                    }
                    return <></>;
                }}
                tableRef={tableRef}
                data_function={getPessoas}
                clickHandler={(row, column) => {
                    if (
                        column.id !== 'options' &&
                        row &&
                        row.original &&
                        row.original.id
                    ) {
                        history.push(`/pessoas/editor/id=${row.original.id}`);
                    }
                }}
            />
        </PageContainer>
    );
}

export default Pessoas;
