import React, { useContext } from 'react';
import { useLocation, useRouteMatch, Link } from 'react-router-dom';

import { AppContext } from '../../app/app-container';
import {
    getTabGroup,
    getGroupInfo,
    getPathPage,
} from '../../configs/tab-groups';
import { colors } from '../../configs/theme';
import Icon from '../icon';
import { Container, Group } from './styles';
import TabTab from './tabtab';

function HeaderNavigation() {
    const { menu } = useContext(AppContext);
    const location = useLocation();
    const routeMatch = useRouteMatch();

    const tabs = getTabGroup(location.pathname, routeMatch.url);

    const group = getGroupInfo(menu, location.pathname, routeMatch.url);

    const links = getPathPage(menu, group, tabs, location.pathname);

    return (
        <Container>
            <Group>
                <h2>{group.title}</h2>
                <nav>
                    {links.map((link, idx) => (
                        <React.Fragment key={idx}>
                            {idx > 0 && (
                                <Icon
                                    size="7px"
                                    className="icon-arrow-paths"
                                    color={colors.grey_path}
                                />
                            )}
                            <Link to={link.link}>{link.title}</Link>
                        </React.Fragment>
                    ))}
                </nav>
            </Group>
            <TabTab tabs={tabs} />
        </Container>
    );
}

// {tabs.map((tab, idx) => (
//     <Tab to={tab.to} key={tab.to} selected={tab.selected}>
//         {tab.title}
//         {tab.selected && (
//             <Bar
//                 absolute
//                 orientation="horizontal"
//                 left
//                 bottom
//                 textWidth
//                 first={idx === 0}
//             />
//         )}
//     </Tab>
// ))}

export default HeaderNavigation;
