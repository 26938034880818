import React, { useContext, useEffect } from 'react';
import { Link, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { error } from '../../components/alerts';
import Error from '../../components/error';
import Header, { HeaderRight } from '../../components/header';
import HeaderMobile from '../../components/header-mobile';
import Icon from '../../components/icon';
import Sidebar from '../../components/sidebar';
import { colors } from '../../configs/theme';
import Atendimento from '../../pages/painel/atendimento';
import Atendimentos from '../../pages/painel/atendimentos';
import Configuracoes from '../../pages/painel/configuracoes';
import Home from '../../pages/painel/home';
import Pessoa from '../../pages/painel/pessoa';
import Pessoas from '../../pages/painel/pessoas';
import Usuarios from '../../pages/painel/usuarios';
import { screens } from '../../utils/Theme';
import { AppContext } from '../app-container';
import PrivateRouter from './router/private-router';
import { AppContent, Container, FullContainer } from './styles';

function Painel() {
    const { logout, screenSize, setIsMenuOpen, user } = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (history.action === 'REPLACE') {
            error('Você não tem permissão para acessar essa página!');
        }
    }, [history.action]);

    useEffect(() => {
        setIsMenuOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <Route>
            <FullContainer>
                <Sidebar />
                <Container direction="column" content="true">
                    {screenSize === screens.laptop ? (
                        <Header>
                            <HeaderRight>
                                <Link to="/usuarios" title="Usuários">
                                    <Icon
                                        className="icon-user"
                                        size="20px"
                                        pointer
                                        color={colors.grey_placeholder}
                                    />
                                </Link>
                                {user.id === '999' && (
                                    <Link
                                        to="/configuracoes"
                                        title="Configurações"
                                    >
                                        <Icon
                                            className="icon-config"
                                            size="20px"
                                            pointer
                                            color={colors.grey_placeholder}
                                        />
                                    </Link>
                                )}
                                <button
                                    title="Sair"
                                    type="button"
                                    onClick={(e) => logout && logout(e)}
                                >
                                    <Icon
                                        className="icon-power-off"
                                        size="20px"
                                        pointer
                                        color={colors.grey_placeholder}
                                    />
                                </button>
                            </HeaderRight>
                        </Header>
                    ) : (
                        <HeaderMobile />
                    )}

                    <AppContent>
                        <Switch>
                            <PrivateRouter
                                publica
                                path="/usuarios"
                                component={Usuarios}
                            />
                            <PrivateRouter
                                publica
                                path="/pessoas/editor"
                                component={Pessoa}
                            />
                            <PrivateRouter
                                publica
                                path="/pessoas"
                                component={Pessoas}
                            />
                            <PrivateRouter
                                publica
                                path="/atendimentos/editor"
                                component={Atendimento}
                            />
                            <PrivateRouter
                                publica
                                path="/atendimentos"
                                component={Atendimentos}
                            />
                            <PrivateRouter
                                publica
                                path="/configuracoes"
                                component={Configuracoes}
                            />
                            <PrivateRouter
                                publica
                                path="/"
                                component={Atendimentos}
                            />
                            <Route component={Error} />
                        </Switch>
                    </AppContent>
                </Container>
            </FullContainer>
        </Route>
    );
}

export default Painel;
