import styled from 'styled-components';

export const Container = styled('div')`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    flex-direction: column;
`;
export const ContainerTermometro = styled('div')`
    width: 100px;
    position: relative;
    margin-right: 20px;
    margin-left: 20px;

    .thermometer .thermometer__bg-color {
        background: linear-gradient(#f44336, #8bc34a, #2196f3);
    }

    .thermometer .thermometer__draw-b:after {
        background: #2196f3;
    }

    > span {
        width: 30px;
        height: 243px;
        position: absolute;
        left: calc(50% - 30px / 2);
        top: 12px;
        z-index: 1;
    }
`;

export const Bottom = styled('div')`
    display: flex;
    width: 100%;
    align-items: center;
`;
