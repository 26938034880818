import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('header')`
    height: 60px;
    background-color: ${colors.secondary_blue};
    display: flex;
    justify-content: flex-start;
`;

export const IconContainer = styled('div')`
    height: 100%;
    width: 60px;
    display: flex;
    z-index: 2;
    justify-content: center;
    align-items: center;
`;

export const SystemName = styled(Link)`
    font-size: ${parse(14)};
    font-weight: bold;
    color: ${colors.white};
    height: 100%;
    padding: 15px;

    strong {
        margin-left: 3px;
    }
`;
