import React, { useContext } from 'react';
import Thermometer from 'react-thermometer-component';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { AppContext } from '../../../app/app-container';
import { screens } from '../../../utils/Theme';
import Mask from '../mask';
import { Label, LabelMessage, Required } from '../styles';
import { Container, ContainerTermometro, Bottom } from './styles';

const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    integerLimit: 2,
    decimalSymbol: ',',
    allowNegative: false,
    allowLeadingZeroes: false,
};

function Termometro({ value, required, onChange, name, disabled = false }) {
    const numberMask = createNumberMask(defaultMaskOptions);
    const { screenSize } = useContext(AppContext);

    return (
        <Container>
            <Bottom>
                <ContainerTermometro>
                    <Thermometer
                        theme="light"
                        value={value}
                        max="45"
                        steps="10"
                        format="°C"
                        size="large"
                        height="260"
                    />
                    <span
                        onClick={(e) => {
                            if (!disabled) {
                                const {
                                    y,
                                    height,
                                } = e.target.getBoundingClientRect();
                                const { clientY } = e;
                                const yClicked = height - (clientY - y);
                                const percent = (
                                    (yClicked * 45) /
                                    height
                                ).toFixed(2);

                                if (onChange) onChange(percent);
                            }
                        }}
                    />
                </ContainerTermometro>
                {screenSize <= screens.smartphone && (
                    <Mask
                        name={name}
                        disabled={disabled}
                        value={`${value}`.replace('.', ',')}
                        mask={numberMask}
                        label="Temperatura"
                        onChange={(val) => {
                            const number = val.target.value.replace(',', '.');
                            if (Number(number) >= 45) {
                                if (onChange) onChange(45);
                            } else if (onChange) onChange(number);
                        }}
                    />
                )}
            </Bottom>
        </Container>
    );
}

export default Termometro;
