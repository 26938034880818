import React, { useRef, useState, useEffect, useContext } from 'react';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';

import { Row } from '../../../app/global-styles';
import { error, success } from '../../../components/alerts';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Icon from '../../../components/icon';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';
import { colors } from '../../../configs/theme';
import Request, { getLimit } from '../../../utils/Request';
import 'moment/locale/pt-br';
import { screens } from '../../../utils/Theme';
import FiltrosAtendimentos from './filtros';
import { AppContext } from '../../../app/app-container';

moment.locale('pt-br');

function Atendimentos() {
    const history = useHistory();
    const tableRef = useRef();
    const [searchParams, setSearchParams] = useState({});
    const [filtrosOpen, setFiltrosOpen] = useState(false);
    const { screenSize, user } = useContext(AppContext);

    const initialFilters = {
        search: '',
        atendentes: [],
        atendidos: [],
        periodo_inicial: '',
        periodo_final: '',
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    async function getAtendimentos({ page, limit: table_limit }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_atendimentos = request.setRequest('atendimentos', 'listar', {
            limit,
            ...searchParams,
        });
        const result = await request.execute();

        if (result && result[req_atendimentos]) {
            return result[req_atendimentos];
        }
        return {};
    }

    async function desativarAtendimento({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest(
                    'atendimentos',
                    'desativar',
                    { id }
                );
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Atendimento excluído com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir atendimento!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir atendimento?',
                subtitle: `Ao confirmar o atendimento ${original.id} será excluído!`,
                onConfirm() {
                    desativar(original).then();
                },
            });
        } else {
            error('Atendimento inválida!');
        }
    }

    function submitFiltros({
        search,
        atendentes,
        atendidos,
        periodo_inicial,
        periodo_final,
    }) {
        const search_params = {};

        search_params.search = search;
        search_params.atendentes = atendentes.map(
            (atendente) => atendente.value
        );
        search_params.atendidos = atendidos.map((atendido) => atendido.value);

        if (periodo_inicial) {
            search_params.periodo_inicial = moment(periodo_inicial).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }
        if (periodo_final) {
            search_params.periodo_final = moment(periodo_final).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }

        setSearchParams(() => {
            setFiltrosOpen(false);
            return search_params;
        });
    }

    function makeFormFiltros(formikState) {
        return (
            <Row as="form" wrap="wrap" onSubmit={formikState.handleSubmit}>
                <FiltrosAtendimentos
                    formState={formikState}
                    filtrosOpen={filtrosOpen}
                    setFiltrosOpen={setFiltrosOpen}
                />
                <Field
                    name="search"
                    type="table-search"
                    placeholder="Filtrar"
                    size={1}
                    minWidth={210}
                    onChange={(e) => {
                        const { value, name } = e.target;
                        setSearchParams({ ...searchParams, [name]: value });
                    }}
                    onClickFiltros={() => {
                        setFiltrosOpen(true);
                    }}
                    clearFunction={() => {
                        setSearchParams({});
                        formikState.resetForm({ ...initialFilters });
                    }}
                    searchParams={searchParams}
                    alias={{
                        tags: 'tags',
                        atendentes: 'atendentes',
                        atendidos: 'atendidos',
                        periodo_inicial: 'período inicial',
                        periodo_final: 'período final',
                    }}
                    component={ControlledInput}
                />
                {Object.keys(searchParams).some(
                    (key) => searchParams[key] && searchParams[key] !== ''
                ) && (
                    <Icon
                        className="icon-limpar"
                        size="26px"
                        color={colors.grey_placeholder}
                        title="Limpar filtros"
                        onClick={() => {
                            setSearchParams({});
                            formikState.resetForm({ ...initialFilters });
                        }}
                        hover={colors.white}
                        marginLeft="15px"
                        kind="action-button"
                    />
                )}
            </Row>
        );
    }

    return (
        <PageContainer padding scroll>
            <Row margin="0 0 21px 0">
                <Formik
                    onSubmit={submitFiltros}
                    initialValues={{ ...initialFilters }}
                >
                    {makeFormFiltros}
                </Formik>
            </Row>
            <Table
                headers={
                    screenSize === screens.smartphone
                        ? [
                              {
                                  name: 'Pessoa',
                                  accessor: 'pessoa_nome',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value || ''
                                              }
                                          />
                                      );
                                  },
                              },
                          ]
                        : [
                              {
                                  name: 'Pessoa',
                                  accessor: 'pessoa_nome',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value || ''
                                              }
                                          />
                                      );
                                  },
                              },
                              {
                                  name: 'Data',
                                  accessor: 'data',
                                  Cell: (celProps) => (
                                      <>
                                          {celProps.value &&
                                              celProps.value
                                                  .split(' ')
                                                  .map((data) =>
                                                      data
                                                          .split('-')
                                                          .reverse()
                                                          .join('/')
                                                  )
                                                  .join(' ')}
                                      </>
                                  ),
                              },
                              {
                                  name: 'Atendente',
                                  accessor: 'atendente_nome',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value || ''
                                              }
                                          />
                                      );
                                  },
                              },
                              {
                                  name: 'Temperatura',
                                  accessor: 'temperatura',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value.replace(
                                                      '.',
                                                      ','
                                                  ) || ''
                                              }
                                          />
                                      );
                                  },
                              },
                              {
                                  name: 'Descrição do atendimento',
                                  accessor: 'assunto',
                                  Cell: (cellProps) => {
                                      return (
                                          <Highlighter
                                              searchWords={
                                                  searchParams &&
                                                  searchParams.search
                                                      ? searchParams.search.split(
                                                            ' '
                                                        )
                                                      : []
                                              }
                                              autoEscape
                                              textToHighlight={
                                                  cellProps.value || ''
                                              }
                                          />
                                      );
                                  },
                              },
                          ]
                }
                options={(table_props) => {
                    if (
                        table_props.original &&
                        table_props.original.atendente &&
                        table_props.original.atendente === user.id
                    ) {
                        return (
                            <Icon
                                hidden
                                size="16px"
                                hover={colors.red_error_message}
                                color={colors.black_table}
                                className="icon-trash"
                                onClick={() =>
                                    desativarAtendimento(table_props)
                                }
                            />
                        );
                    }
                    return <></>;
                }}
                tableRef={tableRef}
                data_function={getAtendimentos}
                clickHandler={(row, column) => {
                    if (
                        column.id !== 'options' &&
                        row &&
                        row.original &&
                        row.original.id
                    ) {
                        history.push(
                            `/atendimentos/editor/id=${row.original.id}`
                        );
                    }
                }}
            />
        </PageContainer>
    );
}

export default Atendimentos;
