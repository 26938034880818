import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';

import { colors } from '../../configs/theme';
import Input from '../form-components/input';
import HeaderNavigation from '../header-navigation';
import Icon from '../icon';
import {
    Container,
    Instituicao,
    StyledHeader,
    StyledHeaderLeft,
    StyledHeaderRight,
} from './styles';

moment.locale('pt-br');

export default function Header(props) {
    const { children, ...attr } = props;

    return (
        <Container>
            <StyledHeader {...attr}>
                <HeaderNavigation />
                {children || null}
            </StyledHeader>
        </Container>
    );
}

Header.propTypes = {
    children: PropTypes.any,
};

export function HeaderLeft(props) {
    const {
        logo,
        className,
        submitBusca,
        input_search,
        input_search_change,
        ...attr
    } = props;

    const submit =
        submitBusca ||
        ((e) => {
            e.preventDefault();
        });

    const classes = className ? `header-left ${className}` : 'header-left';

    return (
        <StyledHeaderLeft className={classes} {...attr}>
            <form className="container_busca" onSubmit={submit}>
                <Input
                    type="text"
                    placeholder="Buscar..."
                    icon="icon-lupa"
                    height="50px"
                    kind="search"
                    name="search-input"
                    background={colors.primary_grey}
                    value={input_search}
                    onChange={input_search_change}
                />
            </form>
        </StyledHeaderLeft>
    );
}

HeaderLeft.propTypes = {
    input_search: PropTypes.string,
    input_search_change: PropTypes.func,
    className: PropTypes.string,
    submitBusca: PropTypes.func,
    logo: PropTypes.string,
};

export function HeaderRight(props) {
    const { className, instituicao, children, username, ...attr } = props;

    const classes = className ? `header-right ${className}` : 'header-right';

    return (
        <StyledHeaderRight className={classes} {...attr}>
            <div className="info">
                <Link to="/">
                    <Instituicao>
                        <strong>SISCOVID</strong> - SECRETARIA DE SAÚDE DE
                        CRIXÁS
                    </Instituicao>
                    <Icon
                        className="icon-nucleo-logo"
                        size="30px"
                        color={colors.primary_blue}
                    />
                </Link>
            </div>

            <div className="actions">{children}</div>
        </StyledHeaderRight>
    );
}

HeaderRight.propTypes = {
    className: PropTypes.string,
    instituicao: PropTypes.string,
    username: PropTypes.string,
    children: PropTypes.any,
};

export function HeaderButton(props) {
    const { className, icon, link, btnStyle, ...outros } = props;

    const classes = ['header-button'];

    // Icone
    if (icon && icon.trim().startsWith('icon-')) {
        classes.push(icon.trim());
    } else if (icon) {
        classes.push(`icon-${icon.trim()}`);
    }

    // Estilo do botão
    if (btnStyle !== 'default') {
        classes.push(btnStyle);
    }

    if (className) {
        classes.push(className);
    }

    const attr = {
        ...outros,
        className: classes.join(' '),
    };

    if (link) {
        return <Link to={link} {...attr} />;
    }
    return <div {...attr} />;
}

HeaderButton.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    link: PropTypes.string,
    btnStyle: PropTypes.oneOf(['default', 'orange']),
};
