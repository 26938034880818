import React, { useState, createContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { useSwipeable } from 'react-swipeable';

import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Loader from '../../components/tableloader';
import { basePath } from '../../data/AppData';
import { menu } from '../../data/Navigation';
import { getUsuario } from '../../data/Usuario';
import Login from '../../pages/painel/login';
import Request from '../../utils/Request';
import { screens } from '../../utils/Theme';
import Painel from '../painel';
import { StyledApp } from './styles';

const browserhistory = createBrowserHistory();

const AppContext = createContext();

function AppContainer({ theme }) {
    const [show_loader, setShowLoader] = useState(true);
    const [show_loader_login, setShowLoaderLogin] = useState(true);
    const [user, setUser] = useState({});
    const [is_logged, setIsLogged] = useState(false);
    const [screenSize, setScreenSize] = useState(theme.screens.laptop);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handlers = useSwipeable({
        onSwipedRight({ initial }) {
            const [xInitial] = initial;

            if (
                screenSize !== screens.laptop &&
                xInitial <= 15 &&
                !isMenuOpen
            ) {
                setIsMenuOpen(true);
            }
        },
        onSwipedLeft() {
            if (screenSize !== screens.laptop && isMenuOpen) {
                setIsMenuOpen(false);
            }
        },
    });

    useEffect(() => {
        const handleWindowSizeChange = () => {
            const { screens } = theme;

            function getSize(value, values) {
                const next = values
                    .sort((a, b) => a - b)
                    .find((val) => val >= value);

                if (!next) {
                    return values[values.length - 1];
                }
                return next;
            }

            const newSize = getSize(window.innerWidth, Object.values(screens));

            if (newSize !== screenSize) {
                setScreenSize(newSize);
            }
        };
        handleWindowSizeChange();

        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenSize]);

    useEffect(() => {
        const start = async () => {
            browserhistory.replace({
                search: '',
            });

            setShowLoader(false);
        };

        start();
    }, []);

    async function logout(e) {
        e.preventDefault();
        const request = new Request();

        const req_logout = request.setRequest('login', 'logout', {});
        const result = await request.execute();

        if (result[req_logout]) {
            window.location.href = '/';
        }
    }

    function getGroup(permissao = '') {
        const grupo_permissao =
            Object.entries(user.permissoes).filter(([index, value]) =>
                value.includes(permissao)
            )[0] || [];

        if (grupo_permissao[0]) {
            return grupo_permissao[0];
        }
        return '';
    }

    function hasPermissao(permissao, group = '') {
        if (group.trim() === '') {
            group = getGroup(permissao);
        }

        if (
            group !== '' &&
            user &&
            user.permissoes &&
            user.permissoes[group] &&
            Array.isArray(user.permissoes[group])
        ) {
            return user.permissoes[group].includes(permissao);
        }
        return !isNaN(Number(user.admin)) && Number(user.admin) === 1;
    }

    function routerPainel() {
        return <Painel />;
    }

    /**
     * Verifica o login do usuário cada vez que uma página do painel é carregada
     *
     * @return {*}
     * @private
     */
    function checkLogin() {
        const checkLoginAsync = async () => {
            const requested_user = await getUsuario();
            const this_is_logged =
                typeof requested_user === 'object' &&
                Object.keys(requested_user).length > 0;

            // Verifica se o estado de login mudou ou se o usuário estava logado e saiu
            if (
                is_logged !== this_is_logged ||
                (!this_is_logged && is_logged)
            ) {
                const newUser =
                    requested_user === 'useroff' ? null : requested_user;
                setUser(newUser);
                setIsLogged(this_is_logged);
            }
            setShowLoaderLogin(false);
        };

        checkLoginAsync();
        // Se o ultimo estado estiver logado acessa o painel
        if (is_logged && user) {
            return routerPainel();
        }
        if (!show_loader_login) {
            return <Route component={Login} />;
        }
        return (
            <StyledApp>
                <Loader loading={show_loader_login} />
            </StyledApp>
        );
    }

    if (show_loader === true) {
        return (
            <StyledApp>
                <Loader loading={show_loader} />
            </StyledApp>
        );
    }
    const context_data = {
        menu,
        screenSize,
        isMenuOpen,
        setIsMenuOpen,
    };

    if (is_logged) {
        context_data.user = user;
        context_data.logout = logout;
        context_data.hasPermissao = hasPermissao;
    }

    return (
        <AppContext.Provider value={context_data}>
            <StyledApp {...handlers}>
                <Router basename={basePath()}>
                    <LastLocationProvider>
                        <Switch>
                            <Route path="/" render={checkLogin} />
                        </Switch>
                    </LastLocationProvider>
                </Router>
            </StyledApp>
        </AppContext.Provider>
    );
}

AppContainer.propTypes = {
    theme: PropTypes.shape({
        media: PropTypes.func,
        laptop: PropTypes.func,
        smartphone: PropTypes.func,
        tablet: PropTypes.func,
        screens: PropTypes.objectOf(PropTypes.number),
    }).isRequired,
};

export default withTheme(AppContainer);
export { AppContext };
