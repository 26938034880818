import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

function getTransitionStateStyle(transitionState) {
    switch (transitionState) {
        case 'entered':
        case 'exiting':
            return css`
                width: 100vw;
            `;
        case 'exited':
        case 'entering':
        default:
            return css`
                width: 0;
            `;
    }
}

function getTransitionStateStyleContent(transitionState) {
    switch (transitionState) {
        case 'entered':
        case 'exiting':
            return css`
                opacity: 1;
                padding: 20px;
            `;
        case 'exited':
        case 'entering':
        default:
            return css`
                overflow: hidden;
                opacity: 0;
                padding: 0;
            `;
    }
}

export const Container = styled('nav')`
    width: 100vw;
    height: 100vh;
    background-color: ${colors.secondary_blue};
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    ${(props) =>
        props.duration &&
        css`
            transition: width ${props.duration}ms;
        `}
    ${(props) => getTransitionStateStyle(props.transitionState)}
`;

export const Content = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100vh - 40px);

    ${(props) =>
        props.duration &&
        css`
            transition: opacity ${props.duration}ms,
                padding ${props.duration / 2}ms;
        `}
    ${(props) => getTransitionStateStyleContent(props.transitionState)}
`;

export const ActionButtons = styled('div')`
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-end;

    div {
        & + div {
            margin-left: 15px;
        }
    }
`;

export const LinkContainer = styled('div')`
    color: ${colors.white};
    font-size: ${parse(26)};
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: bold;

    ${(props) =>
        props.active &&
        css`
            color: ${colors.primary_blue};
        `}
`;

const itemStyle = css`
    width: 100%;
    min-height: 40px;
    align-items: center;
    display: flex;

    & + div {
        margin-top: 20px;
    }
`;

export const StyledItem = styled('li')`
    ${itemStyle}
`;

export const StyledLink = styled(Link)`
    ${itemStyle}
`;

export const GroupContainer = styled('div')`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
`;
