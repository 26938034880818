import styled, { css } from 'styled-components';

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 4px;
    background-color: white;
    position: ${(props) => (props.width ? 'fixed' : 'absolute')};
    z-index: 10;
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    box-shadow: 0px 30px 80px rgba(0, 0, 0, 0.3);

    ${(props) =>
        !props.hidden &&
        css`
            display: none;
        `}
    ${({ theme, ...props }) =>
        props.forceMobile &&
        theme.smartphone`
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            border-radius: 0;
            width: 100vw;
            height: 100vh;
            transform: unset;
    `}
`;

export const ContainerRelative = styled('div')`
    width: ${(props) => props.width};
    height: auto;
    position: relative;

    ${({ theme, ...props }) => {
        return props.forceMobile
            ? theme.media([theme.screens.smartphone, theme.screens.tablet])`
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        `
            : theme.smartphone`
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        `;
    }}
`;

export const MapContainer = styled('div')`
    position: relative;
    width: auto;
    height: auto;
`;

export const IconsContainer = styled('div')`
    position: absolute;
    left: 5px;
    bottom: 15px;
    display: flex;
`;
