import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';

import { Field, Formik } from 'formik';
import moment from 'moment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import * as yup from 'yup';

import { AppContext } from '../../../app/app-container';
import { Row } from '../../../app/global-styles';
import { error, loading, success } from '../../../components/alerts';
import Button from '../../../components/button';
import ControlledInput from '../../../components/form-components/controlled-input';
import PageContainer from '../../../components/page-container';
import Request from '../../../utils/Request';
import { pathname2Query } from '../../../utils/RouterRes';
import { parser } from '../../../utils/Select';
import 'moment/locale/pt-br';
import { screens } from '../../../utils/Theme';
import { InputMask } from './styles';

moment.locale('pt-br');
const defaultMaskOptions = {
    prefix: '',
    suffix: '',
    includeThousandsSeparator: false,
    allowDecimal: true,
    integerLimit: 2,
    decimalSymbol: ',',
    allowNegative: false,
    allowLeadingZeroes: false,
};
function Atendimento() {
    const numberMask = createNumberMask(defaultMaskOptions);
    const [atendimento, setAtendimento] = useState({});
    const [canEdit, setCanEdit] = useState(true);
    const location = useLocation();
    const match = useRouteMatch();
    const url_params = pathname2Query(location.pathname, match.url);
    const history = useHistory();
    const { user, screenSize } = useContext(AppContext);
    const initialValues =
        atendimento && Object.keys(atendimento).length > 0
            ? atendimento
            : {
                  data: new Date(),
                  assunto: '',
                  pessoa: {},
                  temperatura: 36,
              };

    useEffect(() => {
        async function loadPessoa() {
            try {
                const request = new Request();
                const req_pessoa = request.setRequest('pessoas', 'listar', {
                    id: url_params.pessoa,
                });

                const result = await request.execute();

                if (
                    result[req_pessoa] &&
                    result[req_pessoa].dados &&
                    result[req_pessoa].dados.id
                ) {
                    setAtendimento({
                        data: new Date(),
                        assunto: '',
                        pessoa: {
                            label: result[req_pessoa].dados.nome,
                            value: result[req_pessoa].dados.id,
                        },
                        temperatura: 36,
                    });
                }
            } catch (err) {}
        }

        if (url_params.pessoa && !url_params.id) {
            loadPessoa();
        }
    }, []);

    async function handleSubmit(values, state) {
        const request = new Request();
        const atendimento_to_save = {};

        if (atendimento.id) {
            atendimento_to_save.id = atendimento.id;
        }

        atendimento_to_save.assunto = values.assunto;
        atendimento_to_save.temperatura = values.temperatura;

        if (values.data) {
            atendimento_to_save.data = moment(values.data).format(
                'YYYY-MM-DD HH:mm:ss'
            );
        }

        if (values.pessoa.value) {
            atendimento_to_save.pessoa = values.pessoa.value;
        }

        const loadToast = loading('Salvando atendimento');

        try {
            const req_pessoa = request.setRequest('atendimentos', 'salvar', {
                atendimento: atendimento_to_save,
            });

            const result = await request.execute();

            if (result[req_pessoa] === true) {
                loadToast();
                success(`Atendimento alterado com sucesso`);
                state.setSubmitting(false);
            } else if (Number(result[req_pessoa]) > 0) {
                loadToast();
                success(`Atendimento adicionado com sucesso`);
                history.push(`/atendimentos/editor/id=${result[req_pessoa]}`);
                state.setSubmitting(false);
            } else {
                loadToast();
                error('Não foi possível salvar atendimento!');
                state.setSubmitting(false);
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar atendimento!');
            state.setSubmitting(false);
        }
    }

    async function getPessoasSelect(search) {
        const request = new Request();

        const req_pessoas = request.setRequest('pessoas', 'listar', {
            search,
            orderCategoria: true,
        });
        const result = await request.execute();

        const { dados } = result[req_pessoas] || [];

        return parser('nome', 'id', dados);
    }

    async function getAtendimento(id) {
        const request = new Request();

        const req_atendimento = request.setRequest('atendimentos', 'listar', {
            id,
        });

        const result = await request.execute();

        const { dados } = result[req_atendimento];

        if (dados && dados.id) {
            const atendimento_to_edit = {};
            atendimento_to_edit.assunto = dados.assunto;
            atendimento_to_edit.id = dados.id;
            atendimento_to_edit.temperatura = dados.temperatura;

            if (dados.data && dados.data !== '') {
                atendimento_to_edit.data = moment(
                    dados.data,
                    'YYYY-MM-DD hh:mm:ss'
                ).toDate();
            } else {
                atendimento_to_edit.data = '';
            }

            if (dados.pessoa && !isNaN(dados.pessoa)) {
                atendimento_to_edit.pessoa = {
                    value: dados.pessoa,
                    label: dados.pessoa_nome,
                };
            } else {
                atendimento_to_edit.pessoa = {};
            }

            if (user.id !== dados.atendente) {
                setCanEdit(false);
            }

            setAtendimento(atendimento_to_edit);
        } else {
            history.push('/atendimentos');
        }
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getAtendimento(url_params.id).then();
        } else {
            setAtendimento({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url_params.id]);

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row align="center" height="auto" spaceBetween>
                    <Field
                        component={ControlledInput}
                        name="temperatura"
                        label="Temperatura"
                        disabled={!canEdit}
                        type="termometro"
                        required
                        size={4}
                    />
                    <Row height="auto" spaceBetween direction="column">
                        <Row height="auto" spaceBetween>
                            {screenSize >= screens.tablet && (
                                <InputMask
                                    label="Temperatura"
                                    name="temperatura"
                                    disabled={!canEdit}
                                    value={`${formState.values.temperatura}`.replace(
                                        '.',
                                        ','
                                    )}
                                    mask={numberMask}
                                    margin="0 0 5px 0"
                                    onChange={(val) => {
                                        const number = val.target.value.replace(
                                            ',',
                                            '.'
                                        );
                                        if (Number(number) >= 45) {
                                            formState.setFieldValue(
                                                'temperatura',
                                                45
                                            );
                                        } else {
                                            formState.setFieldValue(
                                                'temperatura',
                                                number
                                            );
                                        }
                                    }}
                                />
                            )}
                            <Field
                                component={ControlledInput}
                                name="data"
                                disabled={!canEdit}
                                placeholder="Data"
                                label="Data"
                                type="datetime-picker"
                                required
                                size={4}
                            />
                            <Field
                                component={ControlledInput}
                                name="pessoa"
                                type_select="async"
                                type="select"
                                label="Pessoa"
                                required
                                disabled={!canEdit}
                                placeholder="Pessoa"
                                size={4}
                                defaultOptions
                                cacheOptions
                                loadOptions={getPessoasSelect}
                            />
                        </Row>
                        <Row height="auto" spaceBetween>
                            <Field
                                component={ControlledInput}
                                name="assunto"
                                placeholder="Descreva o atendimento"
                                label="Descreva o atendimento"
                                height="140px"
                                disabled={!canEdit}
                                type="text"
                                as="textarea"
                                size={8}
                            />
                        </Row>
                    </Row>
                </Row>
                <Row
                    contentEnd
                    padding={screenSize === screens.smartphone ? '0' : '0 15px'}
                    ignoreMobile
                >
                    {url_params.id > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                formState.resetForm();
                                history.push('/atendimentos/editor');
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Atendimento">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        data: yup
                            .mixed()
                            .required('Data é obrigatório!')
                            .validDate('Data inválida'),
                        pessoa: yup
                            .object()
                            .validSelect('Pessoa é obrigatório!'),
                        assunto: yup.string(),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    return (
        <PageContainer padding scroll={screenSize === screens.smartphone}>
            {renderForm()}
        </PageContainer>
    );
}

export default Atendimento;
