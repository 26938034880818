import React from 'react';

import { Field } from 'formik';
import PropTypes from 'prop-types';

import { Grid, Row } from '../../../../app/global-styles';
import FiltrosPopup from '../../../../components/filtros-popup';
import ControlledInput from '../../../../components/form-components/controlled-input';
import Request from '../../../../utils/Request';
import { parser } from '../../../../utils/Select';

function FiltrosAtendimentos({ formState, filtrosOpen, setFiltrosOpen }) {
    async function getAtendidosSelect(search) {
        const request = new Request();

        const req_vinculado = request.setRequest('pessoas', 'listar', {
            search,
        });

        const result = await request.execute();

        const { dados } = result[req_vinculado] || [];

        return parser('nome', 'id', dados);
    }

    async function getAtendentesSelect(search) {
        const request = new Request();

        const req_usuarios = request.setRequest('usuarios', 'listar', {
            search,
        });

        const result = await request.execute();

        const { dados } = result[req_usuarios] || [];

        return parser('nome', 'id', dados);
    }

    return (
        <FiltrosPopup
            height="600px"
            title="Filtrar Atendimentos"
            contentOverflow
            onClose={() => {
                setFiltrosOpen(false);
            }}
            onOpen={(setLoading) => {
                async function requestDados() {
                    setLoading(false);
                }

                requestDados().then();
            }}
            open={filtrosOpen}
            submitCallback={() => {
                formState.submitForm();
            }}
        >
            <Row cols={1} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="search"
                    type="text"
                    inPopup
                    label="Busca por texto"
                    placeholder="Escrever..."
                    size={8}
                />
            </Row>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="atendidos"
                    isClearable
                    type_select="async"
                    type="select"
                    label="Atendidos"
                    inPopup
                    placeholder="Atendidos"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getAtendidosSelect}
                />
                <Field
                    component={ControlledInput}
                    name="atendentes"
                    isClearable
                    type_select="async"
                    type="select"
                    label="Atendentes"
                    inPopup
                    placeholder="Atendentes"
                    size={4}
                    isMulti
                    defaultOptions
                    cacheOptions
                    loadOptions={getAtendentesSelect}
                />
            </Grid>
            <Grid cols={2} colSize="50%">
                <Field
                    component={ControlledInput}
                    name="periodo_inicial"
                    inPopup
                    label="Período inicial"
                    type="datetime-picker"
                    size={4}
                />
                <Field
                    component={ControlledInput}
                    name="periodo_final"
                    label="Período Final"
                    inPopup
                    type="datetime-picker"
                    size={4}
                />
            </Grid>
        </FiltrosPopup>
    );
}

FiltrosAtendimentos.propTypes = {
    formState: PropTypes.shape({
        values: PropTypes.objectOf(
            PropTypes.oneOfType([
                PropTypes.arrayOf(
                    PropTypes.shape({
                        value: PropTypes.string,
                        label: PropTypes.string,
                    })
                ),
                PropTypes.string,
                PropTypes.shape({
                    value: PropTypes.string,
                    label: PropTypes.string,
                }),
            ])
        ),
        setFieldValue: PropTypes.func,
        submitForm: PropTypes.func,
    }).isRequired,
    filtrosOpen: PropTypes.bool.isRequired,
    setFiltrosOpen: PropTypes.func.isRequired,
};

// avaliacoes
// canais

FiltrosAtendimentos.defaultProps = {};

export default FiltrosAtendimentos;
