export const menu = [
    {
        icon: 'icon-atendimento',
        link: '/atendimentos',
        title: 'Atendimentos',
        // permissao: ['R_TIPOS_DIARIO', 'M_TIPOS_DIARIO'],
    },
    {
        icon: 'icon-home',
        link: '/',
        title: 'Início',
        hidden: true,
        // permissao: ['R_TIPOS_DIARIO', 'M_TIPOS_DIARIO'],
    },
    {
        icon: 'icon-user',
        link: '/pessoas',
        title: 'Pessoas',
        // permissao: ['R_TIPOS_DIARIO', 'M_TIPOS_DIARIO'],
    },
    {
        link: '/configuracoes',
        title: 'Configurações',
        hidden: true,
    },
    {
        link: '/usuarios',
        title: 'Usuários',
        hidden: true,
    },
];
